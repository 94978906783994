//
//
//
//

import requestFrom from "../accounts/component/createdForm";

export default {
  data() {
    return {
      loading: true
    };
  },

  created() {
    let data = this.$route.query.data;
    let action = this.$route.query.action;
    requestFrom(action, JSON.parse(data));
  },
  methods: {}
};
