function createHiddenForm(action, method, target) {
    let form = document.createElement('form');
    form.setAttribute('action', action);
    form.setAttribute('method', method || 'post');
    form.setAttribute('target', target || '_self');
    form.setAttribute('enctype', 'application/json');
    form.style.display = 'none';
    return form;
}
function appendHiddenField(form, name, value) {
    let field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', name);
    field.setAttribute('value', value);
    form.appendChild(field);
}


export default function jsFormSender(action, params, method, target) {
    let form = createHiddenForm(action, method, target);
    Object.keys(params).map(key => {
        appendHiddenField(form, key, params[key]);
    })
    document.body.appendChild(form);
    form.submit();
    setTimeout(() => {
        document.body.removeChild(form);
    }, 20000)
}